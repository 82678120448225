import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import slugify from 'slugify';

import Layout from "../components/layout"
import Hero from "../components/hero"
import Services from "../components/services"
import SEO from "../components/seo"

export const Head = () => (
  <SEO title={'Discover Meat and Fish Delivery Services Online'} description={'Beef, Bison, Salmon, Bone Broth, Organs, Venison, and much more.'} url={`https://meatbox.fyi`}/>
)

const Home = ({ pageContext }) => {

  const renderServices = (services) => {
    return services.map((service, i) => {
      return(
        <div key={i} className='border my-4 p-4'>
          <a href={`/${slugify(service.data.Name, {lower: true})}`}>{service.data.Name}</a>
        </div>
      )
    })
  }

  const renderStats = () => {
    return (
      <div>
        <p>{pageContext.services.length} Services</p>
      </div>
    )
  }

  return (
    <Layout>
        <Hero header={'The Online Meat and Fish Directory'}/>
        <div className='mx-2'>
          <Services services={pageContext.services}/>
        </div>
    </Layout>
  )
}

export default Home
